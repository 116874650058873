import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
    iconfont: 'mdi',
})

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#34495D',
                secondary: '#4FA0E2',
                action: '#00bfa5',
                light: '#ffffff',
                capucine: '#f7b446',
                error: '#ea5f70',
                greyLighter: '#eeeeee',
                greyLight: '#e0e0e0',
                cocoa: '#d7ccc8',
                black: '#041529',
            },
        },
    },
})
