// Import Component hooks before component definitions
import './component-hooks'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
// eslint-disable-next-line import/extensions
import App from './App.vue'
// eslint-disable-next-line import/order
import router from './router'
import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false

// @ts-ignore
Vue.use(router)

new Vue({
    vuetify,
    router,
    render: h => h(App),
}).$mount('#app')
